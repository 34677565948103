<template>
    <el-dialog
      v-model="visible"
      title="修改密码"
      width="500px"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
    >
      <div style="color: red; margin-bottom: 20px;">
        注：为了您的账户安全，第一次登录请务必修改您的登录密码。修改成功后需要重新登录
      </div>
  
      <el-form ref="ruleFormRef" :model="basicForm" label-position="left" label-suffix=":" label-width="130px" :rules="rules">
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model.trim="basicForm.newPassword" type="password" show-password placeholder="请输入新密码" />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input v-model.trim="basicForm.confirmPassword" type="password" show-password placeholder="请输入确认密码" />
        </el-form-item>
      </el-form>
  
      <div class="dialog-footer" style="display: flex;justify-content: flex-end;">
        <!-- <el-button @click="visible = false">取 消</el-button> -->
        <el-button type="primary" @click="onSubmit(ruleFormRef)">确 定</el-button>
      </div>
    </el-dialog>
</template>
<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { useRouter } from 'vue-router'
import { editPassword } from '@/service/login/index'
import cache from '@/utils/cache'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
    /*
    category
    0 订单
    1 跟进
    2 评价
    */
    category: {
      type: Number,
      default: 0
    }
  },
  components: {

  },
  emits: ['update:page','refresh'],
  setup(props, { emit }) {
    const router = useRouter()
    const visible = ref(false)
    const basicForm = reactive({
        newPassword:null,
        confirmPassword:null,
    })
    const rules = {
        newPassword: [
            { required: true, message: '请输入新密码', trigger: 'blur' },
            { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },
            { pattern: /^[\d\w_@!]+$/, message: '只能输入字母、数字、特殊字符[@、!、_]', trigger: 'blur' },
            // {
            // pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/, message: '必须包含字母和数字', trigger: 'blur'
            // }
        ],
        confirmPassword: [
            { required: true, message: '请输入确认密码', trigger: 'blur' },
            {
            validator: (rule, value, callback) => {
                if (value !== basicForm.newPassword) {
                callback(new Error('两次输入密码不一致!'))
                } else {
                callback()
                }
            }, trigger: 'blur'
            }
        ]
    }
    const open = (type)=> {
      visible.value = true
    }
    const ruleFormRef = ref(false)
    const onSubmit = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          const res = await editPassword({password:basicForm.newPassword})
          if (res.code == 0) {
            ElMessage({
              message: '修改成功!',
              type: 'success',
            })
            visible.value = false
            setTimeout(() => {
                router.push('/login')
                cache.deleteCache('token')
                cache.deleteCache('adminInfo')
            }, 500)
          }
          // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })
    }
    

    return {
        visible,
        basicForm,
        rules,
        ruleFormRef,
        onSubmit,
        open
    }

  }
})
</script>
import { ObjectValue, ObjectValueCollection } from '@/utils/objectValue'

// 内容类型枚举

class One extends ObjectValue {
  constructor() {
    super(1, '预约提醒', 'One')
  }
}

class Two extends ObjectValue {
  constructor() {
    super(2, '修改提醒 ', 'Two')
  }
}
class Three extends ObjectValue {
  constructor() {
    super(3, '取消提醒', 'Three')
  }
}
class Four extends ObjectValue {
  constructor() {
    super(4, '用户评价提醒', 'Four')
  }
}
class Five extends ObjectValue {
  constructor() {
    super(5, '用户打赏提醒', 'Five')
  }
}
  


export class titleType extends ObjectValueCollection {
    constructor() {
      super()
      this.add(new One())
      this.add(new Two())
      this.add(new Three())
      this.add(new Four())
      this.add(new Five())
    }
  }

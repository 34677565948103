<template>
  <div id="nav">
    <el-container class="main-content">
      <el-aside class="el-aside">
        <page-menus :collapse="iscollapse" />
      </el-aside>
      <el-container class="page">
        <el-main class="el-content">
          <div class="page-info">
            <router-view v-slot="props">
              <component :is="props.Component"></component>
              <!-- <keep-alive :include="include">
                <component :is="props.Component"></component>
              </keep-alive> -->
            </router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import PageMenus from "@/components/page-menu/page-menu.vue";
import PageHeader from "@/components/page-header/page-header.vue";

export default defineComponent({
  props: {},
  components: {
    PageMenus,
    PageHeader,
  },
  setup() {
    const iscollapse = ref(true);

    const include = [];

    const getPath = () => {};

    return { iscollapse, include };
  },
});
</script>

<style lang="less">
#nav {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .main-content,
  .page {
    height: 100%;

    .scroll {
      height: 100vh;
      overflow: hidden;
    }
  }

  .el-content {
    // height: calc(100%-48px);
    background-color: rgb(239, 239, 239);
    padding: 0px !important;
  }

  .el-footer {
    display: flex;
    color: #333;
    align-items: center;
  }

  .el-header {
    height: 80px !important;
    padding: 0px !important;
    z-index: 100;
  }

  .el-aside {
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
    cursor: pointer;
    background-color: rgb(61, 58, 67);
    transition: width 0.3s linear;
    scrollbar-width: none;
    width: 122px;

    // .logo {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   margin: 30px 0;

    //   i {
    //     font-size: 40px;
    //     color: white;
    //   }
    // }

    &::-webpack-scrollbar {
      display: none;
    }
  }

  .el-content {
    // height: calc(100% - 48px);
    color: #333;

    .page-info {
      border-radius: 5px;
    }
  }
}
</style>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
}
</style>

<template>
  <div id="page-menu">
    <!-- <div class="logo">
      <el-icon>
        <eleme-filled />
      </el-icon>
    </div> -->
    <div class="menu-content">
      <el-menu active-text-color="#409EFF" :unique-opened="true" :collapse="collapse">
        <template v-for="item in userMenus" :key="item.id">
          <template v-if="item.type == 1 && item.children.length > 0">
            <el-sub-menu :index="item.id + ''" :class="collapse ? '' : 'nav-menu-width'" class="el-sub-menu">
              <template #title>
                <img :src="pic[item.icon].url" alt />
                <span @click="menuClick(item)">{{ item.name }}</span>
              </template>
              <template v-for="submenu in item.children" :key="submenu.id">
                <el-menu-item :index="submenu.id + ''" class="el-menu-item" @click="menuClick(submenu)">
                  <template #title class="sub-item">
                    <span>{{ submenu.name }}</span>
                  </template>
                </el-menu-item>
              </template>
            </el-sub-menu>
          </template>

          <template v-else>
            <el-menu-item class="el-menu-item" :index="item.id + ''" @click="menuClick(item)">
              <img src="~@/assets/img/icon/index.png" alt="">
              <span>{{ item.name }}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </div>

    <div class="bottom">
      <div>
        <el-popover
          ref="popoverRef"
          v-model:visible="popoverVisible"
          placement="right-end"
          trigger="hover"
          width="430px"
        >
        <div class="msg_content">
          <div class="msg_list" v-if="unreadCountList&&unreadCountList.length">
            <div class="msg_item" v-for="(item,index) in unreadCountList" :key="index">
              <div class="msg_title">
                {{item.type?titleTypeformat(item.type):'-'}}
                <div>{{item.time}}</div>
              </div>
              <div class="msg_text"><el-link type="primary" @click="toUserDetail(item.userId)">{{ item.userName }}</el-link>{{item.content}}</div>
            </div>
          </div>
          <el-empty v-else description="暂无消息" />
          <div class="footer">
            <el-checkbox :disabled="!unreadCountList.length" v-model="checked" @change="handleCheckedChange">标记全部为已读</el-checkbox>
            <p @click="handleMessageClick">查看更多</p>
          </div>
        </div>
        <template #reference>
          <el-badge :value="unreadCount" :max="99" class="item">
            <el-button circle >
              <el-icon><Bell /></el-icon>
            </el-button>
          </el-badge>
        </template>
      </el-popover>
      </div>
      <div @click="handleSignOutClick">
        <el-button circle type="primary">
          <el-icon><Avatar /></el-icon>
        </el-button>
      </div>
      <div @click="handleSignOutClick" class="current-user">{{ currentAdminName }}</div>
    </div>
    <el-dialog v-if="dialogVisible" v-model="dialogVisible" :close-on-click-modal="false" @close="closeDialogVisible">
      <template #title>
        <div class="title_flex">
          <p class="title_text">通知</p>
          <div class="right">
            <el-checkbox v-model="checked1" :disabled="!allMsgList.length||status==2" style="margin-right: 20px;" @change="handleCheckedChange1">标记全部为已读</el-checkbox>
            <el-select v-model="status" placeholder="请选择" @change="handleStatusChange">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </template>
      <div class="content">
        <div class="msg_list" style="max-height: 400px" v-if="allMsgList&&allMsgList.length">
          <div class="msg_item" v-for="(item,index) in allMsgList" :key="index">
            <div class="msg_title">
              {{item.type?titleTypeformat(item.type):'-'}}
              <div>{{item.time}}</div>
            </div>
            <div class="msg_text"><el-link type="primary" @click="toUserDetail(item.userId)">{{ item.userName }}</el-link>{{item.content}}</div>
          </div>
        </div>
        <el-empty v-else description="暂无消息" />
      </div>
      <template #footer>
        <el-pagination
          :hide-on-single-page="allMsgTotal<1"
          v-model:current-page="pageForm.current"
          :page-size="pageForm.size"
          layout="prev, pager, next"
          :total="allMsgTotal"
          @current-change="handleCurrentChange">
        </el-pagination>
      </template>
    </el-dialog>
    <ChangePasswords ref="pwd"></ChangePasswords>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, nextTick } from 'vue'
// import { userMenus } from '@/common/config/usermenus'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import localCache from '@/utils/cache'
import cache from '@/utils/cache'
import { popup } from '@/utils/pop-up'
import {getAppointmentMsgList,allRead} from '@/service/main/serve/index'
import { ElMessage } from 'element-plus'
import {titleType} from './config/titleType'
import ChangePasswords from '@/components/changePassword'

export default defineComponent({
  props: { collapse: { type: Boolean, default: false } },
  components: {
    ChangePasswords
  },
  data() {
    return {
      pic: {
        index: {
          url: require('@/assets/img/icon/index.png')
        },
        shop: {
          url: require('@/assets/img/icon/shop.png')
        },
        card: {
          url: require('@/assets/img/icon/card.png')
        },
        order: {
          url: require('@/assets/img/icon/order.png')
        },
        customer: {
          url: require('@/assets/img/icon/customer.png')
        },
        goods: {
          url: require('@/assets/img/icon/goods.png')
        },
        reserve: {
          url: require('@/assets/img/icon/reserve.jpg')
        },
        serve: {
          url: require('@/assets/img/icon/serve.png')
        },
        staff: {
          url: require('@/assets/img/icon/staff.png')
        },
        commission: {
          url: require('@/assets/img/icon/commission.png')
        },
        target: {
          url: require('@/assets/img/icon/target.png')
        },
        marketing: {
          url: require('@/assets/img/icon/marketing.jpg')
        }
      }
    }
  },
  setup() {
    const status = ref(1)
    const router = useRouter()
    const store = useStore()
    const unreadCountList = ref([])
    const unreadCount = ref(0)
    const allMsgList = ref([])
    const allMsgTotal = ref(0)
    const popoverVisible = ref(false)
    const dialogVisible = ref(false)
    const options = reactive([{value:1,label:'未读消息'},{value:2,label:'已读消息'}])
    const pageForm = reactive({current:1,size:5})
    const checked1 = ref(false)
    const checked = ref(false)
    const pwd = ref(null)
    const menuClick = (item) => {
      if (item.children?.length > 0) return
      router.push({ path: item.url })
    }

    const handleSignOutClick = () => {
      popup('您确定退出登录吗?', () => {
        router.push('/login')
        cache.clearCache()
      })
    }
    const titleTypeformat =(name)=>{
          let obj = []
          obj = new titleType().getByValue(name, 'id')
          return obj ? obj.name : ''
      }

    const toUserDetail = (userId) =>{
      router.push({
        path: "/main/customer/detail",
        query: {
          userId
        }
      })
      popoverVisible.value = false
      dialogVisible.value = false
    }
    const handleCheckedChange = async(val) =>{
      if (val) {
        let res = await allRead()
        if (res.code==0) {
          ElMessage.success({
            message: '操作成功!',
            type: 'success'
          })
          popoverVisible.value = false
          checked.value = false
          getMsgUnreadCount()
        }
      }
    }

    const handleCheckedChange1 = async(val) =>{
      if (val) {
        let res = await allRead()
        if (res.code==0) {
          ElMessage.success({
            message: '操作成功!',
            type: 'success'
          })
          getMsgAllList()
          getMsgUnreadCount()
        }
      }
    }

    const closeDialogVisible = ()=>{
      checked1.value = false
      dialogVisible.value = false
    }

    

    const handleMessageClick = () => {
      popoverVisible.value = false
      dialogVisible.value = true
      getMsgAllList()
    }
    const handleCurrentChange = (page) =>{
      pageForm.current = page
      getMsgAllList()
    }
    const handleStatusChange = (val) =>{
      getMsgAllList()
    }
    const getMsgUnreadCount = async() =>{
      let params={
        current:1,
        size:20,
        status:1
      };
      let res = await getAppointmentMsgList(params)
      if (Number(res.data.total)>20) {
        unreadCountList.value = res.data.list.slice(0,20)
      }else{
        unreadCountList.value = res.data.list
      }
      unreadCount.value = Number(res.data.total)
    }
    getMsgUnreadCount()

    const getMsgAllList = async() =>{
      let params={
        current:pageForm.current,
        size:pageForm.size,
        status:status.value
      };
      let res = await getAppointmentMsgList(params)
      allMsgList.value = res.data.list
      allMsgTotal.value = Number(res.data.total)
    }

    const userMenus = cache.getCache("authRoutes")

    const currentAdminName = ref('admin')
    const initPage = () => {
      currentAdminName.value = cache.getCache('currentAdminName')
      const timer = setInterval(() => {
        let adminInfos = localCache.getCache('adminInfo')
        if (adminInfos) {
          getMsgUnreadCount()
        }else{
          clearInterval(timer)
        }
      }, 20000);
      const adminInfo = localCache.getCache('adminInfo')
      if (adminInfo.needEditPassword) {
        nextTick(()=>{
          pwd.value.open()
        })
      }
    }
    initPage()
    // // console.log(userMenus)
    return {
      status,
      userMenus,
      menuClick,
      currentAdminName,
      handleSignOutClick,
      popoverVisible,
      handleMessageClick,
      dialogVisible,
      options,
      unreadCountList,
      toUserDetail,
      allMsgList,
      allMsgTotal,
      pageForm,
      handleCurrentChange,
      handleStatusChange,
      checked,
      checked1,
      handleCheckedChange,
      titleTypeformat,
      handleCheckedChange1,
      closeDialogVisible,
      unreadCount,
      pwd
    }
  }
})
</script>

<style scoped lang="less">
#page-menu {
  background-color: rgb(61, 58, 67);
  width: 100%;
  padding-bottom: 130px;
  position: relative;
  height: 96vh;


  &:deep(.el-scrollbar__bar.is-vertical) {
    width: 2px !important;

    .el-scrollbar__thumb {
      background-color: rgb(255, 255, 255);
      opacity: .8;
    }
  }

  .el-popper.is-light.is-pure {
    left: 124px !important;
  }

  &:deep(.el-popper.is-pure) {
    left: 119px !important;
  }

  &:deep(.el-menu--collapse) {
    width: 100% !important;

    .el-menu-item,
    .el-sub-menu {
      img {
        width: 22px;
        margin-right: 8px;
      }

      span {
        display: block;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        overflow: visible !important;
      }
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 113px;
    background-color: rgb(61, 58, 67);
    height: 80px;

    i {
      font-size: 40px;
      color: white;
    }
  }

  .nav-menu-width {
    width: 202px;
  }

  &:deep(.el-menu) {
    background-color: rgb(61, 58, 67);
    border-right: none !important;

    .el-sub-menu__title {
      color: white !important;
    }

    .el-sub-menu__title:hover {
      color: #7948ea !important;
    }

    .el-menu-item {
      color: white;
    }

    .el-menu-item:hover {
      color: #7948ea !important;
    }

    .el-menu-item.is-active,
    .el-sub-menu.is-active {
      color: #fff !important;
      background-color: #7948ea !important;
    }
  }

  &:deep(.el-menu--vertical) {
    .el-menu-item.is-active {
      color: #fff !important;
      background-color: #7948ea !important;
    }
  }
}
.bottom{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 130px;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  align-items: center;
  .current-user{
    color: #fff;
    font-size: 14px;
  }
}
.msg_content{
  padding: 12px !important;
  .msg_list{
    max-height: 300px;
    overflow-y: auto;
    padding: 6px 0;
    .msg_item{
      margin-bottom: 14px;
      .msg_text{
        font-size: 14px;
      }
      .msg_title{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        margin-bottom: 6px;
        font-weight: 500;
        div{
          font-size: 12px;
          color: #999 !important;
          font-weight: 400;
        }
      }
    }
  }
  .footer{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
    p{
      cursor: pointer;
    }
  }
}
.content{
  // padding: 12px !important;
  min-height: 400px;
  overflow-y: auto;
  .msg_list{
    max-height: 300px;
    overflow-y: auto;
    // padding: 6px 0;
    .msg_item{
      margin-bottom: 14px;
      .msg_text{
        font-size: 14px;
        line-height: 30px;
        &:deep(.el-link){
          vertical-align:top;
        }
      }
      .msg_title{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: 500;
        div{
          font-size: 12px;
          color: #999 !important;
          font-weight: 400;
        }
      }
    }
  }
}
.title_flex{
  display: flex;
  align-items: center;
  width: 96%;
  justify-content: space-between;
  .title_text{
    flex: 1;
  }
  .right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    color: #999;
  }

}
&:deep(.el-popper){
  padding: 0px !important;
}

</style>
<style lang='less'>
.el-menu--vertical {
  .el-menu-item.is-active {
    color: #fff !important;
    background-color:#7948ea !important;
  }
}
</style>
<style>
.scrollbar-demo-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
}
</style>

<template>
  <div class="page-header">
    <div class="top">
      <div class="title">{{ AGENCY_NAME }}</div>
      <div class="form">
        <el-input class="search" prefix-icon="search" suffix-icon="arrow-down" v-model="searchValue"
          placeholder="快捷搜索" />
        <el-icon class="message">
          <message />
        </el-icon>
        <div @click="handleSignOutClick" class="current-user">管理员{{ currentAdminName }}在线</div>
        <el-icon @click="handleSignOutClick">
          <circle-close-filled />
        </el-icon>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { AGENCY_NAME } from '@/common/constant'
import cache from '@/utils/cache'
import { popup } from '@/utils/pop-up'
import router from '@/router'
export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const searchValue = ref('')

    const handleSignOutClick = () => {
      popup('您确定退出登录吗?', () => {
        router.push('/login')
        cache.deleteCache('token')
        cache.deleteCache('adminInfo')

      })

    }

    const currentAdminName = ref('admin')
    const initPage = () => {
      try {
        currentAdminName.value = cache.getCache('currentAdminName')
      } catch (error) {
        // console.log(error);
      }
    }
    initPage()

    return {
      currentAdminName,
      AGENCY_NAME,
      searchValue,
      handleSignOutClick
    }

  }
})
</script>

<style scoped lang="less">
.page-header {
  padding: 0 38px;
  display: flex;
  justify-content: space-between;
  height: 100%;
  border-bottom: 1px solid rgba(241, 243, 244, .6);
  z-index: 99;
  background-color: #fff;


  .top {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: rgba(80, 80, 80, 1);
      font-weight: bold;
      font-size: 14px;
      min-width: 140px;
    }

    .form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      i {
        color: rgba(153, 153, 153, 1);
        font-size: 24px;
      }

      .search {
        max-width: 270px;
        min-width: 230px;
        margin-right: 20px;
      }

      .message {
        padding-right: 20px;
        font-size: 24px;
      }

      .current-user {
        padding: 0 20px;
        white-space: nowrap;
        font-size: 14px;
      }
    }
  }
}
</style>

import { InnerError,isKeyValue } from '@/utils/exception'

/**
 * 配置对象
 * @class ObjectValue
 * @param opt.attr is optional, can add other info
 * opt = { a:{ id:1,name:2,key:'a' } }
 * id & name & key is required
 */
export class ObjectValue {
  // TODO: add opt
  constructor(id, name, key, attr) {
    if (new.target === 'ObjectValue') {
      throw new InnerError({ message: 'ObjectValue Error: ObjectValue can not be new directly' })
    }
    if (id === undefined) {
      throw new InnerError({ message: 'ObjectValue Error: id required' })
    }
    if (name === undefined) {
      throw new InnerError({ message: 'ObjectValue Error: name required' })
    }
    if (key === undefined) {
      throw new InnerError({ message: 'ObjectValue Error: key required' })
    }
    if (attr && !isKeyValue(attr)) {
      throw new InnerError({ message: 'ObjectValue Error: attr required json' })
    }
    this.id = id
    this.name = name
    this.key = key
    // 添加属性到实例
    const that = this
    if (attr) {
      Object.keys(attr).forEach((v, i) => {
        that[v] = attr[v]
      })
    }
  }
}

const _attr = Symbol('_attr')

export class ObjectValueCollection {
  constructor() {
    if (new.target === 'ObjectValue') {
      throw new InnerError({ message: 'ObjectValueCollection Error: ObjectValueCollection can not be new directly' })
    }
    this[_attr] = {}
  }

  add(object) {
    if (!object instanceof ObjectValue) {
      throw new InnerError({ message: 'ObjectValueCollection Error: add function required ObjectValue instance' })
    }
    const key = object.key
    this[_attr][key] = object
  }

  /**
   * 在集合中根据已知的value，查询对应的objectValue
   * @param name
   * @param key 这个value对应的键，e.g id,name..
   * @returns {*}
   */
  getByValue(name, key) {
    if (name === undefined || key === undefined) {
      throw new Error('ObjectValueCollection Error: name and key is required')
    }
    const attr = this[_attr]
    for (const k in attr) {
      if (attr[k][key] === name) {
        return attr[k]
      }
    }
  }

  /**
   * 按照某个键值输出数组格式
   * @param type  id,name,key...
   */
  toArray(type) {
    type = type || 'name'
    const final = []
    const attr = this[_attr]
    for (const k in attr) {
      final.push(attr[k][type])
    }
    return final
  }

  /**
   * 返回对象数组
   */
  toObjectArray() {
    const final = []
    const attr = this[_attr]
    for (const k in attr) {
      const o = attr[k]
      final.push(o)
    }
    return final
  }

  /**
   * 获取Object
   * @param key
   * @returns {*}
   */
  get(key) {
    return this[_attr][key]
  }

  /**
   * 返回某个键值的枚举对象
   */
  toEnum(type) {
    type = type || 'name'
    const final = {}
    const attr = this[_attr]
    for (const k in attr) {
      final[k] = attr[type]
    }

    return final
  }
}

